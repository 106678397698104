<template>
    <div class="culture">
      <van-nav-bar title="乐山文化" >
        <template #left>
          <van-icon :size="24" color="#141212" name="arrow-left" @click="revert()" slot="left" />
        </template>
        <template #right>
          <van-icon :size="24" color="#141212" @click="$router.push('/search')" name="search" slot="right" />
        </template>
      </van-nav-bar>
      <div class="td_tiket_nav">
        <div @click="typeClic(item.code)" class="td_tiket_nav_list " :class="params.code==item.code?'td_tiket_nav_color':''" v-for="item in typeList">
          <span>{{item.name}}</span>
        </div>

      </div>
      <div class="td-brd"></div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div></div>
          <div class="cul_list" v-for="item in list" @click="routerPush(item)">
            <img :src="item.cover" alt="">
            <p v-text="item.title"></p>
            <div class="cul_list_time">
              <span style="color: #AEAEAE" v-show="item.createDate">{{timer(item.createDate)}}</span>
              <span>
                查看详情
                <van-icon :size="14" color="#2ed462" name="arrow"  />
              </span>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
</template>

<script src="./culture.js">

</script>

<style scoped src="./culture.css">

</style>
